import React from "react";
import Header from "../components/Header";
import {
  ServiceCards,
  ServicesHero,
} from "../components/servicesLandingComponents";
import HorizontalCard from "../components/horizontalCard";
import { Footer } from "../components/FooterComp";
import { Helmet } from "react-helmet";

const ServicesPage = () => {
  return (
    <div>
      <Helmet>
        <title>Services | Rdnext Web Technologies LLP</title>
        <meta
          name="description"
          content="Explore the comprehensive range of web development services offered by Rdnext Web Technologies LLP. From custom website development and web application solutions to responsive web design and e-commerce platforms, we have the expertise to meet your digital needs."/>
      </Helmet>
      <Header />
      <ServicesHero />
      <ServiceCards />
      <HorizontalCard
        title={
          "Ready to take the next step toward simplifying your IT solutions?"
        }
      />
      <Footer />
    </div>
  );
};

export default ServicesPage;
