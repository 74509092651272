import React from "react";
import Header from "../components/Header";
import Careers, { FormComp } from "../components/bookNow";
import { Footer } from "../components/FooterComp";
import { Helmet } from "react-helmet";

export const BookNowPage = () => {
  return (
    <div>
      <Helmet>
        <title>Contact | Rdnext Web Technologies LLP</title>
        <meta name="description" content="Schedule a consultation with our expert team and take the next step in unlocking the full potential of your online presence. Whether you need custom web development, web application solutions, or e-commerce platforms, we're here to help." />
      </Helmet>
      <Header />
      <FormComp />
      <Careers />
      <Footer />
    </div>
  );
};
