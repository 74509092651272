import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ApplyModal from "../pages/Modal";
import { ThreeCircles } from "react-loader-spinner";
import CryptoJS from "crypto-js";
import axios from "axios";
import "../css/booknow.css";
import { Link } from "react-router-dom";

export const FormComp = () => {
  const [showMessage, setMessage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    ClientName: "",
    ClientEmail: "",
    ClientService: "",
    ClientMessage: "",
  });

  const Api = process.env.REACT_APP_BASE_URL;
  const Key = process.env.REACT_APP_KEY;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async function handleSubmit(e) {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(formData),
      Key
    ).toString();
    e.preventDefault();
    try {
      setMessage(true);
      let res = await axios.post(Api, { encryptedData });
      if (res.status === 200) {
        setMessage(false);
        navigate("/thank-you");
      }
    } catch (err) {
      setMessage(false);
      setTimeout(function () {
        alert("something went wrong, please reach out to us at +919906007524");
      }, 100);
    }

    setFormData({
      ClientName: "",
      ClientEmail: "",
      ClientService: "",
      ClientMessage: "",
    });
  }

  return (
    <div className="formboxparent">
      <div className="form-child">
        <div className="formBoxConatiner">
          <div className="why-choose-us-title l-align">
            <span className="horizontal-slash"></span>Get In Touch
          </div>
          <h1>Fill The Form Below</h1>
          <p>
            We can find the best solution for your business most handily. Fill
            in the form below to let us know your necessities.
          </p>
          <form onSubmit={handleSubmit} name="contact">
            <input type="hidden" name="form-name" value="contact" />
            <div className="AboutUsFormInputBox">
              <div className="labelAboutUs"> Enter Your Name:</div>
              <input
                type="text"
                name="ClientName"
                value={formData.ClientName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="AboutUsFormInputBox">
              <div className="labelAboutUs"> Enter Your Email:</div>
              <input
                type="email"
                name="ClientEmail"
                value={formData.ClientEmail}
                onChange={handleChange}
                required
              />
            </div>
            <div className="AboutUsFormInputBox">
              <div className="labelAboutUs"> Services Needed:</div>
              <input
                type="text"
                name="ClientService"
                value={formData.ClientService}
                onChange={handleChange}
                placeholder="ex. web development, maintenance"
                required
              />
            </div>
            <div className="AboutUsFormInputBox">
              <div className="labelAboutUs"> Leave Us a Message:</div>
              <textarea
                name="ClientMessage"
                value={formData.ClientMessage}
                onChange={handleChange}
                placeholder="optional"
              />
            </div>
            <div className="termsText">
              On clicking on "Send" you accept our{" "}
              <Link target="blank" to={"/terms-conditions"}>
                <strong>Terms and Conditions</strong>
              </Link>{" "}
              and confirm that you have read our{" "}
              <Link target="blank" to={"/privacy-policy"}>
                <strong>Privacy Policy</strong>
              </Link>
            </div>
            <div className="load-more-button" type="submit">
              {showMessage ? <Thankyou /> : <button>Send</button>}
            </div>
          </form>
        </div>
        <div className="right-from-box">
          <img
            src="https://cdn.pixabay.com/photo/2024/05/07/00/39/schedule-8744592_1280.png"
            alt="business"
          />
        </div>
      </div>
    </div>
  );
};

const Thankyou = () => {
  return (
    <ThreeCircles
      visible={true}
      height="42.500"
      width="100"
      color="#4fa94d"
      ariaLabel="three-circles-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
};

const Careers = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const positions = [
    {
      id: 1,
      title: "Frontend Developer",
      description:
        "Expert in React and CSS. You will be responsible for building responsive and interactive user interfaces.",
    },
    {
      id: 2,
      title: "Backend Developer",
      description:
        "Expert in Node.js and MongoDB. You will be responsible for building robust and scalable backend services.",
    },
    // Add more positions as needed
  ];

  const openModal = (position) => {
    setSelectedPosition(position);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="career-container">
      <section id="careers">
        <div className="container">
          <div className="why-choose-us-title l-align">
            <span className="horizontal-slash"></span>Careers
          </div>
          <h1>Join our team and help us build amazing products</h1>
          <div className="positions">
            {positions.map((position) => (
              <div key={position.id} className="position">
                <h3>{position.title}</h3>
                <p>{position.description}</p>
                <button
                  className="close-button"
                  onClick={() => openModal(position)}
                >
                  Apply Now
                </button>
              </div>
            ))}
          </div>
        </div>
        {selectedPosition && (
          <ApplyModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            position={selectedPosition}
          />
        )}
      </section>
    </div>
  );
};

export default Careers;
