import RippleButton from "./ripplebutton";
import { ServiceCard } from "./serviceCard";
import "../css/servicesLanding.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const ServicesHero = () => {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div className="heroConatiner">
      <div className="hero-section">
        <div className="hero-left-content auto-align">
          <span className="hero-title">Services</span>
          <h2>
            Reliable, customized
            <span className="technology-text"> IT Services </span>designed to
            grow your
            <span className="itsolution-text"> business </span>and give you
            peace of mind.
          </h2>
          <p>
            Taking a reactive approach to IT can be dangerous and costly. Our
            proactive methodology ensures our clients' systems remain
            operational and secure so they can minimize downtime and maximize
            productivity.
          </p>
          <Link to="/contact">
            <RippleButton children={"Let's Connect"} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export const ServiceCards = () => {
  const [visibleCards, setVisibleCards] = useState(6); // Initial number of cards to show

  const cardinfo = [
    {
      id: 2,
      key: "abd",
      cardImg: "https://www.svgrepo.com/show/302570/website.svg",
      cardTitle: "Website Development",
      cardDescription:
        "We specialize in creating stunning, responsive websites that not only look great but also drive business growth. From concept to launch, we manage every aspect of the development process, ensuring your online presence is strong and effective.",
    },
    {
      id: 3,
      key: "abdg",
      cardImg: "https://www.svgrepo.com/show/255097/commerce-ecommerce.svg",
      cardTitle: "E-commerce Solutions",
      cardDescription:
        "Our e-commerce solutions enable businesses to thrive online with seamless shopping experiences, secure payment gateways, and easy product management, helping you increase sales and customer satisfaction.",
    },
    {
      id: 4,
      key: "abe",
      cardImg:
        "https://www.svgrepo.com/show/343841/seo-training-marketing-presentation.svg",
      cardTitle: "SEO & Digital Marketing",
      cardDescription:
        "Boost your online visibility with our expert SEO and digital marketing services. We help you reach more customers by improving your search rankings and running targeted campaigns across social media and other digital platforms.",
    },
    {
      id: 5,
      key: "abf",
      cardImg: "https://www.svgrepo.com/show/376339/netlify.svg",
      cardTitle: "Content Management Systems",
      cardDescription:
        "Our CMS solutions give you full control over your website’s content. Easily update text, images, and other media without any technical expertise, making content management seamless and efficient.",
    },
    {
      id: 6,
      key: "abg",
      cardImg: "https://www.svgrepo.com/show/426044/app-design.svg",
      cardTitle: "Mobile App Development",
      cardDescription:
        "We develop intuitive and engaging mobile applications for iOS and Android platforms, helping your business reach a wider audience and deliver enhanced user experiences on the go.",
    },
    {
      id: 7,
      key: "abh",
      cardImg: "https://www.svgrepo.com/show/530449/cloud-backup.svg",
      cardTitle: "Cloud Hosting & Services",
      cardDescription:
        "Our cloud hosting services provide your business with scalable, secure, and reliable infrastructure. Enjoy the benefits of cloud computing, including remote access, data storage, and flexibility for your growing needs.",
    },
    {
      id: 8,
      key: "abi",
      cardImg: "https://www.svgrepo.com/show/268302/plan.svg",
      cardTitle: "UX/UI Design",
      cardDescription:
        "We create visually stunning and user-friendly interfaces that enhance user experience, making your website both beautiful and easy to navigate for your visitors.",
    },
    {
      id: 9,
      key: "abj",
      cardImg: "https://www.svgrepo.com/show/530439/api-interface.svg",
      cardTitle: "API Integration",
      cardDescription:
        "We provide seamless API integration services, connecting your website with third-party platforms and services to boost functionality and streamline operations.",
    },
    {
      id: 10,
      key: "abk",
      cardImg: "https://www.svgrepo.com/show/524946/server-square-cloud.svg",
      cardTitle: "Web Hosting Services",
      cardDescription:
        "Our web hosting solutions ensure fast, secure, and reliable hosting for your website, keeping it live and performing optimally for your customers.",
    },
    {
      id: 11,
      key: "abl",
      cardImg: "https://www.svgrepo.com/show/487260/db-maintenance.svg",
      cardTitle: "Website Maintenance",
      cardDescription:
        "Our website maintenance services keep your website up to date, secure, and performing smoothly with regular updates, backups, and performance monitoring.",
    },
  ];

  const handleLoadMore = () => {
    console.log("clickrd");
    setVisibleCards(cardinfo.length); // Show all cards
  };

  return (
    <div className="service-cards-container">
      <div className="service-cards">
        {cardinfo.slice(0, visibleCards).map((card) => {
          return (
            <ServiceCard
              key={card.key}
              cardImg={card.cardImg}
              cardTitle={card.cardTitle}
              cardDescription={card.cardDescription}
            />
          );
        })}
      </div>
      {visibleCards < cardinfo.length && ( // Show 'Load More' button if there are hidden cards
        <div className="load-more-button" onClick={handleLoadMore}>
          <button>Load More</button>
        </div>
      )}
    </div>
  );
};
