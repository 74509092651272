import React from "react";
import Header from "../components/Header";
import {
  Expertise,
  HeroHomePage,
  OurClients,
  WhyChooseUs,
  WhyClientsLoveUs,
} from "../components/HomeComp";
import { Footer } from "../components/FooterComp";
import { Helmet } from "react-helmet";

export const Homepage = () => {
  return (
    <div>
      <Helmet>
        <title>
          Best Web Development Services | Rdnext Web Technologies LLP
        </title>
        <meta
          name="description"
          content="Discover the best web development services offered by rdnext. We specialize in custom website design, responsive front-end development, robust back-end solutions, and more. Get in touch today!"
        />
      </Helmet>
      <Header />
      <HeroHomePage />
      <WhyChooseUs />
      <Expertise />
      <OurClients />
      <WhyClientsLoveUs />
      <Footer />
    </div>
  );
};

