import { BrowserRouter, Routes, Route } from "react-router-dom";
import ServicesPage from "./pages/services";
import './App.css'
import About from "./pages/about";
import { BookNowPage } from "./pages/booknow";
import Terms from "./pages/terms";
import PrivacyPolicy from "./pages/privacyPolicy";
import Thankyoupage from "./pages/thankyoupage";
import { Homepage } from "./pages/homepage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route exact path="/services" element={<ServicesPage />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<BookNowPage />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms-conditions" element={<Terms />} />
        <Route exact path="/thank-you" element={<Thankyoupage />} />
      </Routes>
      
    </BrowserRouter>
  );
}

export default App;
