import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";
export const Footer = () => {
  return (
    <footer className="footer">
      <img
        src="https://cdn.pixabay.com/photo/2016/11/19/00/04/world-1837416_1280.png"
        alt="map"
      />
      <div className="footer-content">
        <div className="footer-section about">
          <h2>About</h2>
          <p>
            We are a leading web development company dedicated to delivering
            high-quality, innovative solutions to our clients.
          </p>
          <div className="contact">
            <span>
              <i className="fas fa-phone"></i> (+91) 9906007524
            </span>
            <span>
              <i className="fas fa-envelope"></i> info@rdnext.in
            </span>
          </div>
        </div>
        <div className="footer-section links">
          <h2>Quick Links</h2>
          <ul>
            <li>
              <Link to={"/services"}>Services</Link>
            </li>
            <li>
              <Link to={"/about"}>About us</Link>
            </li>
            <li>
              <Link to={"/contact"}>Book Now</Link>
            </li>
            <li>
              <Link to={"/privacy-policy"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/terms-conditions"}>Terms and Conditions</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section contact-form">
          <h2>Address</h2>
          <p>India</p>
          <p>Jammu and Kashmir</p>
          <p>Umarabad Srinagar - 190012</p>

          <p>
            <a href="/sitemap.xml" rel="noopener noreferrer">
              Sitemap
            </a>
          </p>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; {new Date().getFullYear()} Rdnext Web Technologies LLP website.
        All rights reserved.
      </div>
    </footer>
  );
};
