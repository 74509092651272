
export const ServiceCard = ({ cardImg, cardTitle, cardDescription }) => {
  return (
    <div className="service-card-info">
      <div className="card-img-container">
        <img src={cardImg} alt="card-image" />
      </div>
      <div className="card-title">{cardTitle}</div>
      <div className="card-description">{cardDescription}</div>
      <div className="card-btn">
      </div>
    </div>
  );
};
