import React from "react";
import Wave from "react-wavify";
import "../css/horizontalcard.css";
import RippleButton from "./ripplebutton";
import { Link } from "react-router-dom";

const HorizontalCard = ({ title }) => {
  return (
    <div className="horizontalcard-container">
      <div className="padding-h-box">
        <div className="horizontal-card">
          <div className="horizontal-card-children">
            <div className="horizontal-card-title">{title}</div>
            <div>
              <Link to ='/contact'>
              <RippleButton children={"Contact Us"} />
              </Link>
            </div>
            <Wave
              fill="#1277b0"
              paused={false}
              style={{
                display: "flex",
                borderBottomLeftRadius: "30px",
                borderBottomRightRadius: "30px",
                overflow: "hidden",
              }}
              options={{
                height: 20,
                amplitude: 20,
                speed: 0.15,
                points: 5,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalCard;
