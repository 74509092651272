import React, { useState } from "react";
import "../css/header.css"; // Import the CSS file for styling
import { Link } from "react-router-dom";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };

  return (
    <header className="header">
      <Link to = '/'><div className="logo">
        <span>RDNEXT</span>
      </div></Link>
      
      <nav className={isMobile ? "nav-mobile" : "nav"}>
        
            <Link to="/">Home</Link>
         
            <Link to="/services">Services</Link>
        
       
            <Link to="/about">About</Link>
        
          <Link to="/contact">Contact</Link>
         
      </nav>
      <button className="mobile-menu-icon" onClick={handleToggle}>
        <div className="dot-grid">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
      </button>
    </header>
  );
};

export default Header;
