import "../css/about.css";
import ProgressBar from "@ramonak/react-progress-bar";
import RippleButton from "./ripplebutton";
import { Link } from "react-router-dom";

export const AboutHero = () => {
  return (
    <div className="aboutContainer">
      <div className="aboutChild">
        <div className="about-hero-left">
          <div className="why-choose-us-title l-align">
            <span className="horizontal-slash"></span>About Us
          </div>
          <h1>We Are Increasing Business Success With Technology</h1>
          <p>
            Rdnext is a team of multi-skilled and committed professionals
            serving firms with top-notch solutions for a long time. From robust
            product development and Big Data solutions to DevOps support and
            network monitoring, we offer the best quality solutions to our
            clients using cutting-edge technologies and following the latest
            trends.
            <br />
            <br /> Our team takes pride in offering an extensive range of
            services, including web and mobile application development, cloud
            computing, AWS consulting services, data warehousing, and more. By
            incorporating agile methodologies into our processes, we provide
            highly efficient and cost-effective solutions to our customers. Our
            team is functioning endlessly to serve our clients with the
            solutions that stand out in solving different business challenges
            with full ease. One of our main agendas is to construct a strong
            customer relationship by offering them quality services from the
            initial phases to the maintenance of the developed product.
          </p>
          <div>
            <h2>Website Development</h2>
            <ProgressBar completed={90} maxCompleted={100} />
            <h2>Software Development</h2>
            <ProgressBar completed={70} maxCompleted={100} />
            <br />
            <h1>
              <Link to="/contact">
                <RippleButton children={"Get Started"} />
              </Link>
            </h1>
          </div>
        </div>
        <div className="about-hero-right">
          <img
            src="https://cdn.pixabay.com/photo/2024/05/07/00/42/work-8744593_1280.png"
            alt="hero-about"
          />
        </div>
      </div>
    </div>
  );
};

export const WhyUs = () => {
  return (
    <div className="whyus-container">
      <div className="whyus-child-container">
        <div className="whyus-left">
          <div className="why-choose-us-title l-align">
            <span className="horizontal-slash"></span>Why Us
          </div>
          <h1>Empowering Business Growth Through Innovative Solutions</h1>

          <p>
            Over many years working in IT services developing software
            applications and mobile apps for clients all over the world.
          </p>
          <p>
            We denounce with righteous indignation and dislike men who are so
            beguiled and demoralized by the charms of pleasure of the moment, so
            blinded by desire, that they cannot foresee the pain and trouble
            that are bound to ensue; and equal blame belongs to those who fail
            in their duty through weakness of will, which is the same as saying.
          </p>
          <div className="h2-headings-whyus">
            <div>
              <h2>
                Deliver <span className="horizontal-slash"></span>
              </h2>
              <h2>
                Execute <span className="horizontal-slash"></span>
              </h2>
              <h2>
                Planning <span className="horizontal-slash"></span>
              </h2>
              <h2>
                Discovery <span className="horizontal-slash"></span>
              </h2>
            </div>
            <img
              src="https://www.svgrepo.com/show/470223/up-trend-left.svg"
              alt="up-arrow"
            />
          </div>
        </div>
        <div className="whyus-right">
          <img
            src="https://cdn.pixabay.com/photo/2016/12/19/10/16/hands-1917895_1280.png"
            alt="team"
          />
        </div>
      </div>
    </div>
  );
};
