// RippleButton.js
import React, { useEffect, useState } from 'react';
import '../css/buttoncustom.css'; // Make sure to adjust the path if necessary

const RippleButton = ({ children }) => {
  const [ripples, setRipples] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      createRipple();
    }, 1000); // Adjust the interval duration as needed for continuous effect

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  const createRipple = () => {
    const button = document.querySelector('.ripple-button');
    if (!button) return;

    const rect = button.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = Math.random() * rect.width - size / 2;
    const y = Math.random() * rect.height - size / 2;

    const newRipple = {
      id: Date.now(),
      x,
      y,
      size,
    };

    setRipples((prev) => [...prev, newRipple]);

    // Remove ripple after the animation ends
    setTimeout(() => {
      setRipples((prev) => prev.filter((ripple) => ripple.id !== newRipple.id));
    }, 600); // Duration should match the CSS animation duration
  };

  return (
    <button className="button-global ripple-button">
      {children}
      {ripples.map((ripple) => (
        <span
          key={ripple.id}
          className="ripple"
          style={{
            left: ripple.x,
            top: ripple.y,
            width: ripple.size,
            height: ripple.size,
          }}
        />
      ))}
    </button>
  );
};

export default RippleButton;
