import { Link } from "react-router-dom";
import RippleButton from './ripplebutton'
import "../css/homepage.css";
// import Wave from "react-wavify";
export const HeroHomePage = () => {
  return (
    <div className="heroConatiner">
      <div className="hero-section">
        <div className="hero-left-content">
          <span className="hero-title">Technology & IT Solutions</span>
          <h2>
            Digital <span className="technology-text">Technology,</span>{" "}
            <span className="itsolution-text">IT Solution</span> & Services
            Around the World
          </h2>
          <p>
            We are leading technology solutions providing company all over the
            world doing over many years.
          </p>
          <Link to="/contact">
            <RippleButton children={"Let's Connect"} />
          </Link>
        </div>
        <div className="hero-right-content">
          <img
            src="https://cdn.pixabay.com/photo/2016/09/13/11/08/browser-1666995_640.png"
            alt="svg"
          />
        </div>
      </div>
    </div>
  );
};

export const WhyChooseUs = () => {
  return (
    <div className="container-why-choose-us">
      <div className="why-choose-us">
        <div className="why-choose-us-left">
          <img
            src="https://cdn.pixabay.com/photo/2019/10/09/07/28/development-4536630_1280.png"
            alt="thumnail"
          />
        </div>
        <div className="why-choose-us-right">
          <div className="why-choose-us-title">
            <span className="horizontal-slash"></span>Why Choose Us
          </div>
          <div className="why-choose-us-bigger-text">
            We Create Result-Oriented Dynamic Applications
          </div>
          <div className="why-choose-us-samller-text">
            Over lots of working in IT services developing software
            applications and mobile apps for clients all over the world.
          </div>
          <div className="why-choose-us-imgtxt">
            <div className="why-choose-us-img">
              <img
                src="https://www.svgrepo.com/show/468233/chart-column-grow.svg"
                alt="png"
              />
            </div>
            <div className="why-choose-us-imgtxtbox">
              <p className="why-choose-us-svgtxt">First Growing Process</p>
              <p className="why-choose-us-svgtxt-description">
                We help you create a clear digital strategy
              </p>
            </div>
          </div>
          <div className="why-choose-us-imgtxt">
            <div className="why-choose-us-img">
              <img
                src="https://www.svgrepo.com/show/295826/coding-seo-and-web.svg"
                alt="png"
              />
            </div>
            <div className="why-choose-us-imgtxtbox">
              <p className="why-choose-us-svgtxt">Clean Code</p>
              <p className="why-choose-us-svgtxt-description">
                Our expertise spans all major technologies
              </p>
            </div>
          </div>
          <div className="why-choose-us-imgtxt">
            <div className="why-choose-us-img">
              <img
                src="https://www.svgrepo.com/show/288163/document.svg"
                alt="png"
              />
            </div>
            <div className="why-choose-us-imgtxtbox">
              <p className="why-choose-us-svgtxt">Well Documentation</p>
              <p className="why-choose-us-svgtxt-description">
                Our global Managed Services teams secure
              </p>
            </div>
          </div>
          <div className="why-choose-us-imgtxt">
            <div className="why-choose-us-img">
              <img
                src="https://www.svgrepo.com/show/345342/box-up-package-pack-delivery-square-product-2.svg"
                alt="png"
              />
            </div>
            <div className="why-choose-us-imgtxtbox">
              <p className="why-choose-us-svgtxt">SLA Fulfilment</p>
              <p className="why-choose-us-svgtxt-description">
                Sustainable system for delivering better software
              </p>
            </div>
          </div>
          <Link to="/services">
            <RippleButton children={"Explore"} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export const Expertise = () => {
  // Array of objects representing the cards
  const cards = [
    {
      frontTitle: "Website Development",
      frontContent: "Crafting visually stunning and user-friendly websites.",
      backContent:
        "Our website development services focus on creating engaging, responsive websites tailored to your business needs, ensuring a seamless user experience across all devices.",
      imgLink: "https://www.svgrepo.com/show/302570/website.svg",
    },
    {
      frontTitle: "E-commerce Solutions",
      frontContent:
        "Building online stores that convert visitors into customers.",
      backContent:
        "We provide comprehensive e-commerce solutions, including shopping cart integration, payment gateway setup, and inventory management, to help you maximize your online sales.",
      imgLink: "https://www.svgrepo.com/show/255097/commerce-ecommerce.svg",
    },
    {
      frontTitle: "Content Management Systems",
      frontContent: "Empowering you to manage your content effortlessly.",
      backContent:
        "Our CMS development services enable you to easily update, manage, and publish content on your website, giving you full control without technical expertise.",
      imgLink: "https://www.svgrepo.com/show/376339/netlify.svg",
    },
    {
      frontTitle: "SEO Optimization",
      frontContent: "Boosting your visibility on search engines.",
      backContent:
        "Our SEO optimization services ensure that your website ranks higher on search engines, driving more organic traffic and increasing your online presence.",
      imgLink:
        "https://www.svgrepo.com/show/343841/seo-training-marketing-presentation.svg",
    },
    {
      frontTitle: "Responsive Design",
      frontContent: "Creating websites that look great on any device.",
      backContent:
        "We specialize in responsive design, ensuring that your website adapts beautifully to all screen sizes, from desktops to mobile devices.",
      imgLink: "https://www.svgrepo.com/show/343842/seo-maintenance.svg",
    },
    {
      frontTitle: "Web Hosting Services",
      frontContent: "Reliable hosting solutions for your website.",
      backContent:
        "Our web hosting services provide secure, fast, and reliable hosting solutions to keep your website running smoothly 24/7.",
      imgLink: "https://www.svgrepo.com/show/524946/server-square-cloud.svg",
    },
    {
      frontTitle: "Website Maintenance",
      frontContent: "Keeping your website updated and secure.",
      backContent:
        "We offer ongoing website maintenance services, ensuring your site is always up to date, secure, and functioning optimally.",
      imgLink: "https://www.svgrepo.com/show/487260/db-maintenance.svg",
    },
    {
      frontTitle: "UX/UI Design",
      frontContent: "Designing intuitive and engaging user experiences.",
      backContent:
        "Our UX/UI design services focus on creating visually appealing and user-friendly interfaces that enhance the overall user experience.",
      imgLink: "https://www.svgrepo.com/show/268302/plan.svg",
    },
    {
      frontTitle: "Digital Marketing",
      frontContent: "Promoting your brand in the digital landscape.",
      backContent:
        "We provide digital marketing services, including social media management, email marketing, and online advertising, to help you reach your target audience effectively.",
      imgLink:
        "https://www.svgrepo.com/show/343854/digital-marketing-promotion-advertising.svg",
    },
    {
      frontTitle: "Mobile App Development",
      frontContent: "Creating apps that enhance user engagement.",
      backContent:
        "Our mobile app development services help you connect with your customers on the go, creating intuitive and functional applications for both iOS and Android.",
      imgLink: "https://www.svgrepo.com/show/426044/app-design.svg",
    },
    {
      frontTitle: "API Integration",
      frontContent: "Seamlessly connecting your services.",
      backContent:
        "We offer API integration services to enhance your website's functionality by connecting it with various third-party services and platforms.",
      imgLink: "https://www.svgrepo.com/show/530439/api-interface.svg",
    },
    {
      frontTitle: "Cloud Services",
      frontContent: "Scalable solutions for your business needs.",
      backContent:
        "Our cloud services provide scalable solutions to host, manage, and analyze your data, ensuring optimal performance and flexibility.",
      imgLink: "https://www.svgrepo.com/show/530449/cloud-backup.svg",
    },
  ];

  return (
    <div className="expertise-container">
      <div className="expertise">
        <div className="why-choose-us-title">
          <span className="horizontal-slash"></span>Expertise
        </div>
        <div className="why-choose-us-bigger-text">
          What We Are Providing With Experience
        </div>
        <div className="expertise-card-container">
          {cards.map((card, index) => (
            <div className="flip-card" key={index}>
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={card.imgLink} alt={card.frontTitle} />
                  <h2>{card.frontTitle}</h2>
                </div>
                <div className="flip-card-back">
                  <p>{card.backContent}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const OurClients = () => {
  return (
    <div className="ourClientsContainer">
      <div className="ourClients">
        <div className="section-title-global">
          Technology We Use <span className="horizontal-slash"></span>
        </div>
        <div className="patners-content">
          <div className="partnerImgContainer">
            <img
              src="https://www.svgrepo.com/show/452092/react.svg"
              alt="react-logo"
            />
          </div>
          <div className="partnerImgContainer">
            <img
              src="https://strapi.io/assets/strapi-logo-dark.svg"
              alt="strapi"
            />
          </div>
          <div className="partnerImgContainer">
            <img src="https://www.svgrepo.com/show/448266/aws.svg" alt="aws" />
          </div>
          <div className="partnerImgContainer">
            <img
              src="https://www.svgrepo.com/show/376337/node-js.svg"
              alt="node"
            />
          </div>
          <div className="partnerImgContainer">
            <img
              src="https://seekvectors.com/files/download/234b110fb32958f68f318e13c7a0610e.png"
              alt="cisco"
            />
          </div>
          <div className="partnerImgContainer">
            <img
              src="https://webimages.mongodb.com/_com_assets/cms/kuyjf3vea2hg34taa-horizontal_default_slate_blue.svg?auto=format%252Ccompress"
              alt="mongodb"
            />
          </div>
          <div className="partnerImgContainer">
            <img
              src="https://www.svgrepo.com/show/353935/jira.svg"
              alt="jira"
            />
          </div>
          <div className="partnerImgContainer">
            <img src="https://www.svgrepo.com/show/341068/sql.svg" alt="sql" />
          </div>
          <div className="partnerImgContainer">
            <img
              src="https://www.svgrepo.com/show/448226/gitlab.svg"
              alt="jitlab"
            />
          </div>
          <div className="partnerImgContainer">
            <img
              src="https://www.svgrepo.com/show/452111/teams.svg"
              alt="teams"
            />
          </div>

          <div className="partnerImgContainer">
            <img
              src="https://www.svgrepo.com/show/353925/javascript.svg"
              alt="javascript"
            />
          </div>
          <div className="partnerImgContainer">
            <img
              src="https://www.svgrepo.com/show/475656/google-color.svg"
              alt="google"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const WhyClientsLoveUs = () => {
  return (
    <div className="WhyClientsLoveUsContainer">
      <div className="WhyClientsLoveUs">
        <div className="section-title-global">
          Why Clients Love To Work With Us
          <span className="horizontal-slash"></span>
        </div>
        <div className="container-whyclientsloveus">
          <div className="section-whyclientsloveus">
            <img
              src="https://www.svgrepo.com/show/211054/fast-forward.svg"
              alt="fast response"
            />
            <div>
              <strong>Bring Together the Right People in Team</strong>
            </div>
            <p>
              That has very much been the secret of our consistent success over
              the years.
            </p>
          </div>
          <div className="section-whyclientsloveus">
            <img
              src="https://www.svgrepo.com/show/228716/chatting-talk.svg"
              alt="friendly support"
            />
            <div>
              <strong>Efficient Sprint Planning</strong>
            </div>
            <p>
              We plan an efficient sprint roadmap for team members to
              collaborate effectively.
            </p>
          </div>
          <div className="section-whyclientsloveus">
            <img
              src="https://www.svgrepo.com/show/150753/interactive.svg"
              alt="proactive support"
            />
            <div>
              <strong>Code Tech Architecture</strong>
            </div>
            <p>
              We decouple the codes which enable our team to work faster and
              independently.
            </p>
          </div>
          <div className="section-whyclientsloveus">
            <img
              src="https://www.svgrepo.com/show/489674/delivery-package.svg"
              alt="fast response"
            />
            <div>
              <strong>Iterative Delivery Approach</strong>
            </div>
            <p>
              We break the implementation process into smaller pieces to deliver
              value incrementally.
            </p>
          </div>
          <div className="section-whyclientsloveus">
            <img
              src="https://www.svgrepo.com/show/526947/code-square.svg"
              alt="fast response"
            />
            <div>
              <strong>Code Reviews</strong>
            </div>
            <p>
              We identify issues like file leaks, memory leaks, and performance
              signs with code reviews.
            </p>
          </div>
          <div className="section-whyclientsloveus">
            <img
              src="https://www.svgrepo.com/show/195772/levels-ui.svg"
              alt="fast response"
            />
            <div>
              <strong>Standups and Demos</strong>
            </div>
            <p>
              Weekly reviews, demos, and standups ensure coordination among team
              member.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
