// src/components/ApplyModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import '../css/modal.css';
import axios from 'axios';


Modal.setAppElement('#root');
const ApplyModal = ({ isOpen, onRequestClose, position }) => {
    const [showMessage, setMessage] = useState(true);
    const [btnTxt, setBtnTxt] = useState("Please Wait...")

  const [candidate, setCandidate] = useState({
    name: '',
    email: '',
    resume: null,
  });
console.log(candidate)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCandidate({ ...candidate, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCandidate({ ...candidate, resume: file });
  };

  const submitApplication = async (e) => {
    e.preventDefault();
    setMessage(false)

    // Create FormData object to send file along with other data
    const formData = new FormData();
    formData.append('name', candidate.name);
    formData.append('email', candidate.email);
    formData.append('resume', candidate.resume);
    formData.append('position', position.title);


    try {
      const response = await axios.post('https://spangled-polarized-hare.glitch.me/jobs', formData);
      if(response.status===200){
        setMessage(false)
        setBtnTxt("We have recieved you application! Best of Luck")
        setCandidate({
          name : "",
          email: "",
          resume : ""
        })

      }

      
    } catch (error) {
      alert('Please use doc or pdf');
      setMessage(true)
    }

    
  };

  return (
    <>
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Apply for Position"
      className="modal"
      overlayClassName="overlay"
    >
      <h2>Apply for {position.title}</h2>
      <p>{position.description}</p>
      <form onSubmit={submitApplication}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={candidate.name}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={candidate.email}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Resume:
          <input
            type="file"
            name="resume"
            onChange={handleFileChange}
            required
          />
        </label>

        {showMessage ?  <button className='close-button' type="submit">Submit</button> : <>{btnTxt}</>  }
      </form>
      <button onClick={onRequestClose} className="close-button">
        Close
      </button>
    </Modal>
</>
  );
};



export default ApplyModal;
